var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "d-flex justify-content-between align-items-center pb-0"
  }, [_c('div', {
    staticClass: "truncate"
  }, [_c('h1', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.statistic.in_out) + " | " + _vm._s(_vm.statistic.in_only) + " "), _c('small', [_vm._v("( IN Area | IN Total )")])]), _c('span', {
    staticClass: "text-center"
  }, [_c('span', [_c('b-badge', {
    staticClass: "badge-glow button",
    attrs: {
      "variant": "primary"
    }
  }, [_c('strong', [_vm._v("IN Area :")])]), _vm._v(" SAL ")], 1), _vm._v(" ( Realtime location ) "), _c('span', {
    staticClass: "text-center"
  }, [_vm._v("Tidak terikat Filter "), _c('b-badge', {
    staticClass: "badge-glow button",
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" By Today ")])], 1)]), _c('hr'), _c('span', {
    staticClass: "text-center"
  }, [_c('b-badge', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('strong', [_vm._v("IN Total :")])]), _vm._v(" " + _vm._s(_vm.$t('graph_in_total')) + " "), _c('br'), _vm._v("( Realtime Filter "), _c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_c('b-badge', {
    staticClass: "badge-glow button",
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" By Today ")]), _vm._v(" / "), _c('b-badge', {
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.todaySal) + " ) ")])], 1), _c('br'), _c('span', {
    staticClass: "text-center"
  }, [_vm._v("Base on "), _c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" Clock IN Time ")])], 1)], 1)])]), _c('vue-apex-charts', {
    attrs: {
      "type": "line",
      "height": "100",
      "width": "100%",
      "options": _vm.chartOptionsComputed,
      "series": _vm.chartData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }